import React from "react"
import {
  FaUsersCog,
  FaServicestack,
  FaCode,
  FaHandsHelping,
  FaCogs,
  FaServer,
} from "react-icons/fa"
const data = [
  {
    id: 1,
    icon: <FaUsersCog className="service-icon" />,
    title: "Headless CMS",
    text: `For organizations today, the ability to rapidly manage and deliver omnichannel, high-impact digital experiences is critical to fuel great customer experiences.`,
    slug: "/services/fullStack/",
  },
  {
    id: 2,
    icon: <FaServicestack className="service-icon" />,
    title: "Rest API",
    text: `REST APIs provide a flexible, lightweight way to integrate applications, and is the most common method for connecting components in microservices architectures.`,
    slug: "/services/fullStack",
  },
  {
    id: 3,
    icon: <FaCode className="service-icon" />,
    title: "GraphQL API",
    text: `GraphQL gives clients the power to ask for exactly what they need and nothing more, makes it easier to evolve APIs over time, and enables powerful developer tools.`,
    slug: "/services/fullStack",
  },
  {
    id: 4,
    icon: <FaHandsHelping className="service-icon" />,
    title: "Jamstack Architecture",
    text: `The core principles of pre-rendering, and decoupling, enable sites and applications to be delivered with greater confidence and resilience than ever before.`,
    slug: "/services/fullStack/",
  },
  {
    id: 5,
    icon: <FaCogs className="service-icon" />,
    title: "E-commerce Development",
    text: `Using the jamstack principlis, our team can build your businessna super fast e-commerce on-lin solutions, where you can choose the payment serice that suits your needs.`,
    slug: "/services/fullStack",
  },
  {
    id: 6,
    icon: <FaServer className="service-icon" />,
    title: "Web Hosting",
    text: `We can offer great value hosting static sites on a CDN service a cheap viable option, while we have a number of competitive options for you to host your backend security.`,
    slug: "/services/fullStack",
  },
]

export default data
