const FullStackCrumbs = [
  {
    id: 1,
    title: "Home",
    slug: "/",
  },
  {
    id: 2,
    title: "Services",
    slug: "/services",
  },
  {
    id: 3,
    title: "Web Development Services",
    slug: "/services/development",
  },
  {
    id: 4,
    title: "Ful Stack Development",
    slug: "/services/fullStack",
  },
]

export default FullStackCrumbs
